import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Header from './headerpages';
import Footer from './footeroffline';
import gsap from 'gsap';
import '../css/img-scroll.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactSession } from 'react-client-session';
gsap.registerPlugin(ScrollTrigger);
ReactSession.setStoreType("localStorage");

function Offlinecategory() {
  if(!ReactSession.get("arkuserId")){
    ReactSession.set("arkuserId","0");
  }
    const queryParameters = new URLSearchParams(window.location.search)
    const category = queryParameters.get("id") 
    const [productData, setproductData] = useState(null);
    const [selectedVarient, setSelectedVarient] = useState([]);
    const [preProduct, setPreProduct] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    

    // const openModal1 = (id) => {
    //     setSelectedId(id);
    //     setPreProduct([id]);
    //     const modal = document.getElementById('copyModal1');
    //     if (modal) {
    //         modal.classList.add('show');
    //         modal.style.display = 'block';
    //         document.body.classList.add('modal-open');
    //     }
    //   };

    const openModal1 = (id) => {
      const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
  
      
      const existingItemIndex = existingCartItems.findIndex(item => item.id === id);
  
      if (existingItemIndex !== -1) {
        
          openModalsalready(); 
      } else {
        
          setSelectedId(id);
          setPreProduct([id]); 
  
          const modal = document.getElementById('copyModal1');
          if (modal) {
              modal.classList.add('show');
              modal.style.display = 'block';
              document.body.classList.add('modal-open');
          }
      }
  };
      
      const closeModal1 = () => {
        const modal = document.getElementById('copyModal1');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
      };

      const contPreOrder = () => {
        addToCart(selectedId);
      };


    const handleSizeClick = (index, p_id,) => {
      setSelectedVarient({index: index, pId: p_id});
      //console.log(selectedVarient);
    };

  useEffect(() => {
  const fetchData = async () => {

    try {
       
      const response = await axios.get('https://api.arivomkadai.com/offlineproduct/');
      const products = response.data.data.filter(item =>item.visibility ==='0');
    setproductData(products);
    console.log(products)
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

}, []);

const categories = productData && productData.filter(item => item.category === category); 
console.log(categories);

const openModal = () => {
    const modal = document.getElementById('copyModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('copyModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
  
  const [warning, setWarning] = useState('no');
  
  const handleYesClick = () => {
      setWarning('yes');
      sessionStorage.removeItem('preorder');
      closeModal(); 
  };
  
  const handleNoClick = () => {
      setWarning('no');
      closeModal(); 
  };
  
  
  useEffect(() => {
    const storedPreorderItem = JSON.parse(sessionStorage.getItem('preorder'));
    if(storedPreorderItem === null || storedPreorderItem.length ===  0){
          setWarning('yes');
    }
  }, []);


  
const openModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
    
  }
  
  
  
};

const closeModalsalready = () => {
  const modal = document.getElementById('copyModalsready');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};




const openmodalpreorder = () => {
  const modal = document.getElementById('copyModalspreorder');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
      
  }
  

  
};

const closeModalspreorder = () => {
  const modal = document.getElementById('copyModalspreorder');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};
  
  const addToCart = async (productId) => {
    if (warning === 'yes') {
      sessionStorage.removeItem('preorder');
    // Get the existing cart items from session storage
    const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
   
  
    // Log the existing cart items
    // console.log('Existing Cart Items:', existingCartItems);
  
    // Check if the product is already in the cart
    const existingItemIndex = existingCartItems.findIndex(item => item.id === productId);
    const product = productData && productData.filter(items => items.p_id === productId);
    // console.log(product);
  
    // Retrieve the product's stock information
    const productStock = product[0].stock;
  
    // Check if the product is already in the cart
    if (existingItemIndex !== -1) {
        // Calculate the new quantity if the product is added to the existing quantity
        // const updatedQuantity = existingCartItems[existingItemIndex].quantity + 1;
  
        // // Check if adding the product will exceed the available stock
        // if (updatedQuantity <= productStock) {
        //     // If there's sufficient stock, update the quantity
        //     existingCartItems[existingItemIndex].quantity = updatedQuantity;
        // } else {
        //     // If there's insufficient stock, show an alert
        //     alert(`Insufficient stock for ${product[0].product}`);
        //     return; // Stop further execution
        // }
        openModalsalready();
    } else {
        // If the product is not in the cart, check if there's sufficient stock
       
            // If there's sufficient stock, add the product as a new item
            let size = 0;
            let mrp=0;
            let purchase=0;
        let price = 0;
        let weight = 0;
        let courier=0;
        let deliverycharge=0;
        let shippingweight=0;
        let width=0;
        let height=0;
        let breadth=0;
        let gst=0;
        if ( product[0].variations.length > 0){
        if (selectedVarient.hasOwnProperty('pId') && selectedVarient.pId === productId.toString()) {
          size = product[0].variations[selectedVarient.index].value;
          price = product[0].variations[selectedVarient.index].price;
          mrp = product[0].variations[selectedVarient.index].mrp;
          purchase = product[0].variations[selectedVarient.index].p_price;
          weight = product[0].variations[selectedVarient.index].weights;
          courier = product[0].variations[selectedVarient.index].courier;
          deliverycharge = product[0].variations[selectedVarient.index].deliveryCharge;
          shippingweight = product[0].variations[selectedVarient.index].shippingweight;
          width = product[0].variations[selectedVarient.index].width;
          height = product[0].variations[selectedVarient.index].height;
          breadth = product[0].variations[selectedVarient.index].breadth;
          gst = product[0].variations[selectedVarient.index].gst;
        } else {
          size = product[0].variations[0].value;
          price = product[0].variations[0].price; 
          weight = product[0].variations[0].weights; 
          mrp = product[0].variations[0].mrp; 
          purchase = product[0].variations[0].p_price; 
          deliverycharge = product[0].variations[0].deliveryCharge; 
          courier = product[0].variations[0].courier; 
          shippingweight = product[0].variations[0].shippingweight; 
          width = product[0].variations[0].width; 
          height = product[0].variations[0].height; 
          breadth = product[0].variations[0].breadth; 
          gst = product[0].variations[0].gst; 
        }
        }
        else{
          size = '';
          price = product[0].price;
          purchase = product[0].p_price; 
          mrp = product[0].mrp; 
          courier = product[0].courier; 
          deliverycharge = product[0].deliveryCharge; 
          shippingweight = product[0].shippingweight; 
          width = product[0].width; 
          height = product[0].height; 
          breadth = product[0].breadth; 
          weight = product[0].weight;
          gst = product[0].gst;


        }
        if (productStock >= 1) {
        const newItem = {
          id: productId,
          weight: weight,
          size: size,
          code: product[0].product_code,
          product: product[0].product,
          price: price,
          mrp: mrp,
          purchase: purchase,
          deliverycharge : deliverycharge,
          courier : courier,
          quantity: 1,
          image: product[0].image,
          width: width,
          shippingweight: shippingweight,
          height: height,
          breadth: breadth,
          gst: gst,
          preorder: 'no',
          vendor:product[0].vendor,
          vendorcomission:product[0].vendorcommission,
          hsn: product[0].hsn
        };
  
            // Add the new item to the cart
            existingCartItems.push(newItem);
            alert(`${product[0].product} added to cart!`);
        } else {
            if (preProduct.includes(productId)) {
                const newItem = {
                    id: productId,
                    weight: weight,
                    size: size,
                    code: product[0].product_code,
                    product: product[0].product,
                    price: price,
                    mrp: mrp,
                    purchase: purchase,
                    deliverycharge : deliverycharge,
                    courier : courier,
                    quantity: 1,
                    image: product[0].image,
                    width: width,
                    shippingweight: shippingweight,
                    height: height,
                    breadth: breadth,
                    gst: gst,
                    preorder: 'yes',
                    vendor: product[0].vendor,
                    vendorcomission: product[0].vendorcommission,
                    hsn: product[0].hsn
                  };
                  existingCartItems.push(newItem);
                  closeModal1();
            }else{
            // If there's insufficient stock, show an alert
            alert(`Insufficient stock for ${product[0].product}`);
            return; // Stop further execution
            }
        }
    }
  
    // Store the updated cart items back in session storage
    sessionStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    //alert(`${product[0].product} added to cart!`);
  }else {
      openModal();
    }
  };


  
    return (
       <>
    <div class="sidebars">
    <a  className="sidebars-on" href="/online-products">
	    <h3 class="bar-content">Online Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-bar">
       
  
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='/online-products' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                  
                </div>
            </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalspreorder" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalspreorder}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">Insufficent Stock{productData && ( productData[0].product )}</p>
                        <button className="button mr-10 mt-10" onClick={closeModalspreorder}>Cancel</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade custom-modal" id="copyModalsready" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalsalready}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      
                        <p class="mb-30">{productData && (  productData[0].product )}is already in cart</p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade custom-modal" id="copyModal1" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
         <div class="modal-dialog" style={{maxWidth: '35%'}}>
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal1}></button>
              <div class="modal-body bg-remover">
                <div class="login_wrap widget-taber-content background-white">
                  <div class="padding_eight_all bg-white" align="center">
                    <div class="heading_s1">
                      <h4 class="mb-30">Out of Stock</h4>
                       <p class="mb-30">Currently, this product is out of stock. Our executive will contact you within 24 hours after placing your order to discuss the delivery time.</p>
                       
                    
                         </div>
                    <button className="button mr-10 mt-10" onClick={contPreOrder}>Add to Cart</button>
                    <button className="button mr-10 mt-10" onClick={closeModal1}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal1 fade custom-modal" id="copyModal" tabindex="-1" aria-labelledby="copyModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" style={{maxWidth: '35%'}}>
                                                        <div class="modal-content">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                                                            <div class="modal-body bg-remover">
                                                                <div class="login_wrap widget-taber-content background-white">
                                                                <div class="padding_eight_all bg-white" align="center">
                                                                        <div class="heading_s1">
                                                                            <h4 class="mb-5">Warning</h4>
                                                                            <p class="mb-30">Your cart contains items from Regular Products. Would you like to reset your cart for adding items from Pre-Order Products?</p>
                                                                        </div>
                                                                        <button className="button mr-10 mt-10" onClick={handleYesClick}><i className="fa-regular fa-copy"></i> Yes</button>
                                                                        <button className="button mr-10 mt-10" onClick={handleNoClick}><i className="fa-regular fa-copy"></i> No</button>
                             
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>

        {categories && Array.isArray(categories) && categories.length > 0 ? (
      
        <section class="product-tabs section-padding position-relative">
            <div class="container">
                <div class="section-title style-2 wow animate__animated animate__fadeIn">
                   
                </div>
             
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                        <div class="row product-grid-4">
						
						{categories && Array.isArray(categories) && categories.map(product => (
						product.variations.length > 0 ? (
                            <div class="col-xl-1-5 col-lg-3 col-md-4 col-12 col-sm-6">
                                <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                    <div class="product-img-action-wrap">
                                        <div class="product-img product-img-zoom">
                                            <a href={`offlineproduct?id=${product.p_id}`}>
                                                <img class="default-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                                <img class="hover-img" src={`https://api.arivomkadai.com/product_data/${product.image}`} alt={product.product} />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="product-content-wrap">
                                       
                                        <h2><a href={`offlineproduct?id=${product.p_id}`}>{product.product.length > 50 ? `${product.product.slice(0, 50)}...` : product.product}</a></h2>
                                        
                                       
                                        <div class="product-card-bottom">
                                        {(product.variations.length) > 1 ?(<>
                                        
                                        <div class="product-price">
                                             {selectedVarient.hasOwnProperty('pId') && selectedVarient.pId === product.p_id.toString() ? (<span>₹ {product.variations[selectedVarient.index].price} <del class="text-dark">₹  {product.variations[selectedVarient.index].mrp}</del></span>) : (<span>₹ {product.variations[0].price} <del class="text-dark">₹  {product.variations[0].mrp}</del></span>)} 
                                        </div>
                                      


<Dropdown>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
    { selectedVarient.hasOwnProperty('pId') && selectedVarient.pId === product.p_id.toString() ? (product.variations[selectedVarient.index].value+''+product.variations[selectedVarient.index].weights) : (product.variations[0].value+''+product.variations[0].weights) }
    </Dropdown.Toggle>
   
    <Dropdown.Menu>
    {product.variations.map((variation, index) => (
      
       <Dropdown.Item  className= {selectedVarient.hasOwnProperty('pId') && selectedVarient.pId === variation.p_id.toString() && selectedVarient.index === index ? 'active' : '' } onClick={() => handleSizeClick(index, variation.p_id)} >
        {variation.value+' '+variation.weights}
      </Dropdown.Item>
     
    ))}
    </Dropdown.Menu>
    
  </Dropdown>

                                    </>):(<><div class="product-price">
                                            <span>₹  {product.variations[0].price} <del class="text-dark">₹  {product.variations[0].mrp}</del></span>
                                        </div></>)}

                                      </div>
                                   
                                    <div class="product-card-bottom">
                                        <div class="add-cart" style={{minWidth: '100%'}}>
                                        {product.stock <= 0 ?(<>
                                            {product.preorder === '1' ? (<><a class='add' style={{minWidth: '100%', textAlign: 'center'}} onClick={() => openmodalpreorder(product.p_id)}  ><i class="fi-rs-shopping-cart mr-5 w-100"></i>PreOrder</a></>):(<>
                                              <a class='add' style={{minWidth: '100%', textAlign: 'center'}} onClick={() => openModal1(product.p_id)}  ><i class="fi-rs-shopping-cart mr-5 w-100"></i>PreOrder</a>
                                            </>)}
                                            </>  
                                          ):(
                                            <>
                                            <a class='add' style={{minWidth: '100%', textAlign: 'center'}} onClick={() => addToCart(product.p_id)}  ><i class="fi-rs-shopping-cart mr-5 w-100"></i>Add Cart </a>
                                          </>
                                          )}
                                            </div>
                                    </div>
                                        
                                    </div>
                                </div>
                            </div>
                        ):""
                            ))}
                          
                        </div>
                  
                    </div>
                   
                </div>
             
            </div>
        </section>

        ) : (
            
            <section class="home-slider position-relative mb-30 mt-50" style={{minHeight:'90vh'}}>
            <div class="container">
                <div class="home-slide-cover mt-30">
                    <div class="hero-slider-1 ">
                        <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: 'url(assets/imgs/slider/slider-1.png)'}}>
                            <div class="slider-content container">
                                <h3>
                                   No Products Available at this moment. Please check back later!.
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        )}
       
    </main>

    <Footer />

    </div>
    </>
    );
}

export default Offlinecategory;